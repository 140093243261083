.label {
  display: flex;
  justify-content: space-between;
}

.group[data-group] {
  margin: 0;
  padding: 0;
}

.groupHeading[data-group-selected] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 0;

  &:hover {
    background-color: #deebff;
  }

  &[data-group-selected='true'] {
    background-color: #2684ff;
    color: white;
  }
}

.groupExpandToggle {
  height: 40px;
  display: flex;
  align-items: center;
}

.option {
  &[data-group-option='true'] {
    padding-left: 40px;
  }

  &[data-hidden='true'] {
    display: none;
  }
}
